// extracted by mini-css-extract-plugin
export var band = "about-module--band--6fTMk grid--row--E93eT";
export var company = "about-module--company--b8JKz type--font-futura--HOUH4 type--text-m---eRFt";
export var contactContainer = "about-module--contact-container--0B56J grid--row--E93eT";
export var contactItem = "about-module--contact-item--lxqGw grid--col--Jq13z";
export var contentContainer = "about-module--content-container--aIpD2 grid--col-md-12--wVJt8 grid--col-lg-7--kVjBU";
export var contentWrapper = "about-module--content-wrapper--Dp4MA";
export var date = "about-module--date--0LJtl type--font-futura--HOUH4 type--text-m---eRFt";
export var headWrapper = "about-module--head-wrapper--+-DGI grid--col-md-9--IxEuh grid--col-lg-4--RuHdH";
export var hero = "about-module--hero--9-eZW grid--row--E93eT";
export var heroHeader = "about-module--hero-header--Ii0Bv type--font-museo--Rt53G";
export var heroSubHeader = "about-module--hero-sub-header--xfYaC type--font-futura--HOUH4 type--heading-s--JfTSy";
export var heroWrapper = "about-module--hero-wrapper--2SbBl grid--col-sm-6--7dy9X grid--col-md-4--DgprU grid--push-md-1--IjwEr";
export var hobbies = "about-module--hobbies--Gz3NN type--font-futura--HOUH4 type--text-l--+NsZF";
export var imageContainer = "about-module--image-container--LMFSh grid--row--E93eT";
export var imageParent = "about-module--image-parent--KoL47";
export var imageWrapper = "about-module--image-wrapper--rsgH2 grid--col-sm-10--Pm6tZ grid--col-md-9--IxEuh grid--col-lg-6--B-wRl";
export var infoContentContainer = "about-module--info-content-container--rnbKi grid--col-md-8--sJzqa grid--col-lg-6--B-wRl";
export var infoContentWrapper = "about-module--info-content-wrapper--j+HEG";
export var infoHeadWrapper = "about-module--info-head-wrapper--HU442 grid--col-md-4--DgprU grid--col-lg-3--0BWHd grid--push-lg-1--nj0g8";
export var intro = "about-module--intro--5G4eg type--font-essonnes--k0IEZ";
export var introContainer = "about-module--intro-container--f63ph grid--row--E93eT";
export var introWrapper = "about-module--intro-wrapper--IpcxQ grid--col-md-10--GZEu1 grid--col-lg-9--KkUdn";
export var line = "about-module--line--WbLz+ elements--divider--9oGzu";
export var outro = "about-module--outro--dGR6A type--font-essonnes--k0IEZ";
export var outroContainer = "about-module--outro-container--cY8mF grid--row--E93eT";
export var outroWrapper = "about-module--outro-wrapper--W488O grid--col-md-9--IxEuh grid--col-lg-7--kVjBU";
export var position = "about-module--position--FCfv0 type--font-museo--Rt53G type--text-l--+NsZF";
export var sectionContainer = "about-module--section-container--jbBl1 grid--row--E93eT";
export var sectionCopy = "about-module--section-copy--wqKak type--font-futura--HOUH4 type--text-m---eRFt";
export var sectionHeader = "about-module--section-header--gawoT type--font-essonnes--k0IEZ type--heading-m--wRwTa";
export var sectionSubhead = "about-module--section-subhead--aUiIA type--font-museo--Rt53G undefined";
export var skills = "about-module--skills--C+jEr";
export var skillsContainer = "about-module--skills-container--hegEB grid--col-md-6--uS2Fp";
export var skillsItem = "about-module--skills-item--lJUoF type--font-futura--HOUH4 type--text-l--+NsZF";